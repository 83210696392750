import { MayBeNull } from '@wpp-open/core'
import { createContext, PropsWithChildren, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { useIntegrationsApi } from 'api/tenant/queries/useIntegrationsApi'
import { WrikeContact } from 'api/wrike/fetchers/fetchContactsMyApi'
import { useContactsMyApi } from 'api/wrike/queries/useContactsMyApi'
import { WrikeAuthPostEvent } from 'auth/wrike/types'
import { ApiQueryKeys } from 'constants/apiQueryKeys'
import { useToast } from 'hooks/useToast'
import { queryClient } from 'providers/osQueryClient/utils'
import { Integration } from 'types/integrations/integration'
import { IntegrationType } from 'types/projects/Integration'

interface Props {
  myContact: MayBeNull<WrikeContact>
  isWrikeAuthLoading: boolean
  isWrikeAuthenticated: boolean
  isWrikeAvailable: boolean
  integrationAvailable: boolean
  isIntegrationsLoading: boolean
  activeIntegration: MayBeNull<Integration>
}

export const WrikeAuthContext = createContext<Props>(null!)

export const WrikeAuthProvider = ({ children }: PropsWithChildren<{}>) => {
  const { t } = useTranslation()
  const { showToast } = useToast()

  const { data: integrations, isLoading: isIntegrationsLoading } = useIntegrationsApi({ staleTime: 60 * 1000 })

  const isWrikeEnabled = useMemo(() => {
    return integrations?.find(({ type, available }) => type === IntegrationType.WRIKE && available)
  }, [integrations])

  const integrationAvailable = useMemo(
    () => (integrations ?? []).filter(integration => integration.available).length > 0,
    [integrations],
  )
  const activeIntegration = useMemo(
    () => (integrations ?? []).find(integration => !!integration.activeForUser) ?? null,
    [integrations],
  )

  const isWrikeAvailable = !!isWrikeEnabled
  const { data: myContact, isLoading: isWrikeAuthLoading } = useContactsMyApi({ enabled: isWrikeAvailable })

  const isWrikeAuthenticated = !!myContact

  useEffect(() => {
    const childResponse = (e: any) => {
      // Webpack also using postMessage, so we need to catch only our own messages so wrike=true is temp solution
      // Also it's important to check origin
      if (e.origin === window.location.origin && e.data?.wrike) {
        const eventData: WrikeAuthPostEvent = e.data
        if (eventData.error) {
          showToast({
            type: 'error',
            message: t('project.wrike.auth_generic_error'),
          })
          console.error('Wrike integration error')
          return
        }

        queryClient.invalidateQueries([ApiQueryKeys.WRIKE_ME])
        queryClient.invalidateQueries([ApiQueryKeys.PROJECTS_BY_ID])
        queryClient.invalidateQueries([ApiQueryKeys.PROJECT_WRIKE_ACCESS])
        queryClient.invalidateQueries([ApiQueryKeys.MEMBERS])
      }
    }

    window.addEventListener('message', childResponse)

    return () => window.removeEventListener('message', childResponse)
  }, [showToast, t])

  return (
    <WrikeAuthContext.Provider
      value={{
        myContact,
        isWrikeAuthenticated,
        isWrikeAuthLoading,
        isWrikeAvailable,
        integrationAvailable,
        isIntegrationsLoading,
        activeIntegration,
      }}
    >
      {children}
    </WrikeAuthContext.Provider>
  )
}
